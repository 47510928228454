.reflection-content ul, .reflection-content ol {
    padding-left: 20px;
}

.reflection-content ul {
    list-style-type: disc;
}

.reflection-content ol {
    list-style-type: decimal;
}

.reflection-content a {
    color: #007bff;
    text-decoration: underline;
}

.reflection-content ul ul, .reflection-content ol ol {
    list-style-type: circle;
    margin-left: 20px;
}

.reflection-content ul ul ul, .reflection-content ol ol ol {
    list-style-type: square;
    margin-left: 20px;
}

.reflection-content h1, .reflection-content h2, .reflection-content h3 {
    font-weight: bold;
}

.reflection-content h1 {
    font-size: 2em;
}

.reflection-content h2 {
    font-size: 1.5em;
}

.reflection-content h3 {
    font-size: 1.25em;
}
